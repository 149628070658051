import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ViacepService extends GlobalService {

  constructor(private _http: HttpClient) {
    super(_http);
    this.serviceUrl = '';
    this.baseUrl = 'https://viacep.com.br/ws/';
  }

  getAddress(cep: string) {
    return this.get(null, `${cep}/json`);
  }


}
